<template>
  <span>
    <Fa
      :icon="['fas', 'circle-check']"
      class="mr-1"
    />
    {{ t('identityVerified') }}
  </span>
</template>

<script setup lang="ts">
const { t } = useI18n()
</script>

<i18n lang="json">
{
  "en": {
    "identityVerified": "Identity verified"
  },
  "fr": {
    "identityVerified": "Identité vérifiée"
  }
}
</i18n>
